import React, { useState } from "react";
import Header from "./NavBar";
import Services from "../components/Services";
import Works from "../components/Works";
import About from "../components/About";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Layout = (props: any) => {
  const [selectedMember, setSelectedMember] = useState("dr_laith_hussein");

  const filterData = (memberName: string) => {
    setSelectedMember(memberName);
  };
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Blog />
      <Services isIndex={props.isIndex} />
      {/* <Pricing /> */}
      <About filterData={filterData} />
      <Works selectedMember={selectedMember} />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
