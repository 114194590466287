import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertColor,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SelectClinicType } from "./selectors/selectClinicType";
import {
  format,
  addDays,
  subDays,
  isBefore,
  startOfToday,
  subYears,
} from "date-fns";
import { asnanyFactory } from "../api/apiFactory";
import { clinicFactory } from "../clinics_api/apiFactory";
import SelectDentist from "./selectors/asnany/SelectDentist";
import jsPDF from "jspdf";
import { font } from "../lang/font/Rubik-Regular-normal";
import SelectAsnanyOperationType from "./selectors/asnany/SelectOperationType";
import SelectClinicsOperationType from "./selectors/clinics/SelectOperationType";
import { Link } from "react-router-dom";
import logo from "../assets/images/Logo/logo png black.png";

const asnanyApi = asnanyFactory.get("appointment");
const clinicApi = clinicFactory.get("appointment");

interface ClinicTypes {
  name: string;
  id: number;
}

interface Appointments {
  time: string;
}

interface OperationTypes {
  name: string;
  id: number;
}

interface Dentist {
  id: string;
  full_name: string;
}

export default function Appointment() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const asnanyTenantId = localStorage.getItem("AsnanyTenantId");
  const clinicTenantId = localStorage.getItem("ClinicTenantId");

  const { t } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [clinicType, setClinicType] = React.useState<ClinicTypes | null>(null);
  const [operationType, setOperationType] =
    React.useState<OperationTypes | null>(null);
  const [dentist, setDentist] = React.useState<Dentist | null>(null);

  // ======================== patient info form ===========================
  const [patientName, setPatientName] = React.useState<string>("");
  const [patientAddress, setPatientAddress] = React.useState<string>("");
  const [patientPhone, setPatientPhone] = React.useState<string>("");
  const [patientEmail, setPatientEmail] = React.useState<string>("");
  const [patientAge, setPatientAge] = React.useState<string>("");
  const [patientNote, setPatientNote] = React.useState<string>("");
  const [patientGender, setPatientGender] = React.useState<string>(t("female"));
  const genderOptions = [t("male"), t("female")];

  const today = startOfToday();
  const [selectedDay, setSelectedDay] = React.useState<Date>(today);
  const [selectedHour, setSelectedHour] = React.useState<number | null>(null);
  const [appointments, setAppointments] = React.useState<Appointments[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [reservationDetails, setReservationDetails] = React.useState<any>(null);
  const [openSnackBar, setOpenSnackBar] = React.useState<{
    open: boolean;
    message: string;
    color: AlertColor;
  }>({
    open: false,
    message: "",
    color: "success",
  });

  React.useEffect(() => {
    if (clinicType?.id === 1) {
      setLoading(true);
      asnanyApi
        .getReservation({
          dentist_id: dentist?.id ?? "",
          date: format(selectedDay, "yyyy-MM-dd"),
        })
        .then((res: any) => {
          setLoading(false);
          setAppointments(res.data.data);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    } else if (clinicType?.id === 2) {
      setLoading(true);
      clinicApi
        .getClinicsReservation({
          dentist_id: dentist?.id ?? "",
          date: format(selectedDay, "yyyy-MM-dd"),
        })
        .then((res: any) => {
          setLoading(false);
          setAppointments(res.data.data);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  }, [dentist?.id, selectedDay, clinicType]);

  const handleNextDay = () => {
    setSelectedDay(addDays(selectedDay, 1));
    setSelectedHour(null);
  };

  const handlePreviousDay = () => {
    if (!isBefore(subDays(selectedDay, 1), today)) {
      setSelectedDay(subDays(selectedDay, 1));
      setSelectedHour(null);
    }
  };

  const handleHourClick = (hour: number) => {
    setSelectedHour(hour);
  };

  const workingHours = Array.from({ length: 8 }, (_, i) => i + 9);

  const [isOperation, setIsOperation] = React.useState("");

  const handleAppointmentType = (
    event: React.MouseEvent<HTMLElement>,
    newValue: any
  ) => {
    setIsOperation(newValue);
    if (newValue === "exam") {
      setOperationType(null);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleNext();
  };

  const calculateBirthdate = (age: number): string => {
    const birthdate = subYears(new Date(), age);
    return format(birthdate, "yyyy-MM-dd");
  };

  const makeReservation = async () => {
    const combineDateAndTime = (date: Date, hour: number | null): string => {
      if (hour === null) {
        throw new Error("Hour is not selected");
      }
      const combinedDate = new Date(date);
      combinedDate.setHours(hour, 0, 0, 0);
      return format(combinedDate, "yyyy-MM-dd'T'HH:mm:ss");
    };

    const reservationData = {
      tenant_id: clinicType?.id === 1 ? asnanyTenantId : clinicTenantId,
      is_treatment: isOperation === "exam" ? 1 : 0,
      patient_name: patientName,
      patient_birthdate: calculateBirthdate(parseInt(patientAge, 10)),
      patient_gender: patientGender,
      patient_mobile: patientPhone,
      patient_address: patientAddress,
      dentist_id: dentist?.id,
      date: combineDateAndTime(selectedDay, selectedHour),
      note: patientNote,
      operation_type_id: operationType?.id,
    };

    setReservationDetails({
      tenant_id: clinicType?.id === 1 ? asnanyTenantId : clinicTenantId,
      is_treatment: isOperation === "exam" ? 1 : 0,
      patient_name: patientName,
      patient_gender: patientGender,
      patient_mobile: patientPhone,
      patient_address: patientAddress,
      date: combineDateAndTime(selectedDay, selectedHour),
      note: patientNote,
      patient_birthdate: patientAge,
      dentist_id: dentist?.full_name,
      operation_type_id: operationType?.name,
    });

    try {
      if (clinicType?.id === 1) {
        await asnanyApi.create(reservationData);
      } else {
        await clinicApi.createClinics(reservationData);
      }

      setDialogOpen(true);
      setOpenSnackBar({
        open: true,
        message: t("success"),
        color: "success",
      });
    } catch (error: any) {
      setOpenSnackBar({
        open: true,
        message: error.response.data.errors[0],
        color: "error",
      });
      console.error("Error making reservation:", error);
    }
  };

  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  //   setActiveStep(0);
  //   setClinicType(null);
  //   setOperationType(null);
  //   setDentist(null);
  //   setPatientName("");
  //   setPatientAddress("");
  //   setPatientPhone("");
  //   setPatientEmail("");
  //   setPatientAge("");
  //   setPatientNote("");
  //   setIsOperation("");
  //   setSelectedHour(null);
  //   setOpenSnackBar({
  //     open: false,
  //     message: "",
  //     color: "success",
  //   });
  // };

  const handleDownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [148, 210],
    });

    doc.addFileToVFS("Rubik-Regular-normal.ttf", font);
    doc.addFont("Rubik-Regular-normal.ttf", "Rubik-Regular", "normal");
    doc.setFont("Rubik-Regular");

    const pageWidth = doc.internal.pageSize.getWidth();
    const savedLanguage = localStorage.getItem("language");

    const addText = (
      text: string,
      x: number,
      y: number,
      align: "left" | "center" | "right" = "left"
    ) => {
      if (align === "right") {
        const textWidth = doc.getTextWidth(text);
        x = pageWidth - x - textWidth;
      } else if (align === "center") {
        const textWidth = doc.getTextWidth(text);
        x = (pageWidth - textWidth) / 2;
      }
      doc.text(text, x, y);
    };

    const img = new Image();
    img.src = logo;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      const logoBase64 = canvas.toDataURL("image/png");

      const logoWidth = 40;
      const logoHeight = 40;
      const logoX = 10;
      const logoY = 10;

      const textX = logoX + logoWidth + 10;
      const textY = logoY + logoHeight / 2 + 3;

      doc.addImage(logoBase64, "PNG", logoX, logoY, logoWidth, logoHeight);
      addText(
        `${t("reservation_details")}`,
        textX,
        textY,
        savedLanguage === "ar" ? "right" : "left"
      );

      const textAlign = savedLanguage === "ar" ? "right" : "left";

      addText(
        `${t("operation")}: ${reservationDetails?.operation_type_id}`,
        10,
        50,
        textAlign
      );
      addText(
        `${t("patient_name")}: ${reservationDetails?.patient_name}`,
        10,
        60,
        textAlign
      );
      addText(
        `${t("patient_age")}: ${reservationDetails?.patient_birthdate}`,
        10,
        70,
        textAlign
      );
      addText(
        `${t("patient_gender")}: ${reservationDetails?.patient_gender}`,
        10,
        80,
        textAlign
      );
      addText(
        `${t("patient_phone_number")}: ${reservationDetails?.patient_mobile}`,
        10,
        90,
        textAlign
      );
      addText(
        `${t("patient_address")}: ${reservationDetails?.patient_address ?? ""}`,
        10,
        100,
        textAlign
      );
      addText(
        `${t("doctor")}: ${reservationDetails?.dentist_id ?? ""}`,
        10,
        110,
        textAlign
      );
      addText(
        `${t("date")}: ${
          reservationDetails?.date
            ? format(new Date(reservationDetails.date), "yyyy-MM-dd - hh:mm")
            : ""
        }`,
        10,
        120,
        textAlign
      );
      // addText(`${t("patient_note")}: ${reservationDetails?.note ?? ""}`, 10, 130, textAlign);

      doc.save("reservation_details.pdf");

      setOpenSnackBar({
        open: false,
        message: "",
        color: "success",
      });
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        px: 2,
        py: 3,
        direction: "ltr",
      }}
    >
      <Box
        sx={{
          minWidth: smallScreen ? 300 : 400,
          width: smallScreen ? "100%" : "",
        }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>{t("select_type")}</StepLabel>
            <StepContent>
              <SelectClinicType
                onChange={(e, value) => {
                  setClinicType(value ? value : null);
                }}
                value={clinicType ?? null}
              />
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={clinicType ? false : true}
                  sx={{ mt: 1, mr: 1, width: 120 }}
                >
                  {t("continue")}
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>{t("select_service")}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    value={isOperation}
                    exclusive
                    onChange={handleAppointmentType}
                    aria-label="text alignment"
                    size="small"
                    fullWidth
                  >
                    <ToggleButton value="exam">{t("exam")}</ToggleButton>
                    <ToggleButton value="operation">
                      {t("operation")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  {clinicType?.id === 2 ? null : (
                    <SelectDentist
                      onChange={(e, value) => {
                        setDentist(value ? value : null);
                      }}
                      value={dentist ?? null}
                    />
                  )}
                </Grid>
                {isOperation === "exam" ? null : (
                  <Grid item xs={12}>
                    {clinicType?.id === 1 ? (
                      <SelectAsnanyOperationType
                        onChange={(e, value) => {
                          setOperationType(value ? value : null);
                        }}
                        value={operationType ?? null}
                      />
                    ) : (
                      <SelectClinicsOperationType
                        onChange={(e, value) => {
                          setOperationType(value ? value : null);
                        }}
                        value={operationType ?? null}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1, width: 120 }}
                  disabled={
                    clinicType?.id === 1
                      ? (isOperation === "exam" && dentist) ||
                        (dentist && operationType && isOperation)
                        ? false
                        : true
                      : isOperation === "exam" || (operationType && isOperation)
                      ? false
                      : true
                  }
                >
                  {t("continue")}
                </Button>
                <Button
                  // disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {t("back")}
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>{t("patient_info")}</StepLabel>
            <StepContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder={t("patient_name")}
                      required
                      onChange={(e) => setPatientName(e.target.value)}
                      value={patientName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      placeholder={t("patient_phone_number")}
                      required
                      onChange={(e) => {
                        setPatientPhone(e.target.value);
                      }}
                      value={patientPhone}
                      error={
                        !/^07\d{9}$/.test(patientPhone) && patientPhone !== ""
                      }
                      helperText={
                        !/^07\d{9}$/.test(patientPhone) && patientPhone !== ""
                          ? t("phone_number_invalid")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      placeholder={t("patient_age")}
                      required
                      onChange={(e) => setPatientAge(e.target.value)}
                      value={patientAge}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={genderOptions}
                      getOptionLabel={(option) => option}
                      value={patientGender}
                      onChange={(event, newValue) => {
                        setPatientGender(newValue ? newValue : "Female");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("patient_gender")}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder={
                        t("patient_address") + " (" + t("optional") + " )"
                      }
                      onChange={(e) => setPatientAddress(e.target.value)}
                      value={patientAddress}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder={
                        t("patient_email") + " (" + t("optional") + " )"
                      }
                      onChange={(e) => setPatientEmail(e.target.value)}
                      value={patientEmail}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mb: 2 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ mt: 1, mr: 1, width: 120 }}
                    disabled={!/^07\d{9}$/.test(patientPhone)}
                  >
                    {t("continue")}
                  </Button>
                  <Button
                    // disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {t("back")}
                  </Button>
                </Box>
              </form>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>{t("appointment_date")}</StepLabel>
            <StepContent sx={{ p: 0 }}>
              <Box sx={{ my: 2, position: "relative" }}>
                {loading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 3,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handlePreviousDay}
                    disabled={isBefore(subDays(selectedDay, 1), today)}
                    sx={{ width: 100 }}
                  >
                    {t("previous")}
                  </Button>
                  <Typography>{format(selectedDay, "yyyy-MM-dd")}</Typography>
                  <Button
                    variant="contained"
                    onClick={handleNextDay}
                    sx={{ width: 100 }}
                  >
                    {t("next")}
                  </Button>
                </Box>
                <Box>
                  {workingHours.map((hour) => {
                    const formattedHour = hour.toString().padStart(2, "0");
                    const appointment = appointments.find(
                      (appt) => appt.time === `${formattedHour}:00`
                    );
                    return (
                      <Box
                        key={hour}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 1,
                        }}
                      >
                        <Typography>{`${hour}:00 - ${hour + 1}:00`}</Typography>
                        <Button
                          variant="contained"
                          onClick={() => handleHourClick(hour)}
                          disabled={appointment !== undefined}
                          sx={{ ml: 2, width: 100 }}
                        >
                          {appointment !== undefined
                            ? t("reserved")
                            : selectedHour === hour
                            ? t("selected")
                            : t("reserve")}
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  sx={{ mt: 2 }}
                  placeholder={t("patient_note")}
                  onChange={(e) => setPatientNote(e.target.value)}
                  value={patientNote}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={makeReservation}
                  sx={{ mt: 1, mr: 1, width: 200 }}
                  disabled={selectedDay && selectedHour ? false : true}
                >
                  {loading ? <CircularProgress size="small" /> : t("finish")}
                </Button>
                <Button
                  // disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {t("back")}
                </Button>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        <Dialog open={dialogOpen}>
          <DialogTitle>{t("reservation_details")}</DialogTitle>
          <DialogContent>
            <Typography>
              {t("patient_name")}: {reservationDetails?.patient_name}
            </Typography>
            <Typography>
              {t("patient_age")}: {reservationDetails?.patient_birthdate}
            </Typography>
            <Typography>
              {t("patient_gender")}: {reservationDetails?.patient_gender}
            </Typography>
            <Typography>
              {t("patient_phone_number")}: {reservationDetails?.patient_mobile}
            </Typography>
            <Typography>
              {t("patient_address")}: {reservationDetails?.patient_address}
            </Typography>
            <Typography>
              {t("doctor")}: {reservationDetails?.dentist_id}
            </Typography>
            <Typography>
              {t("date")}:{" "}
              {reservationDetails?.date
                ? format(
                    new Date(reservationDetails.date),
                    "yyyy-MM-dd - hh:mm a"
                  )
                : ""}
            </Typography>
            <Typography>
              {t("patient_note")}: {reservationDetails?.note}
            </Typography>
            <Typography>
              {t("operation")}: {reservationDetails?.operation_type_id}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <Button
              variant="contained"
              onClick={handleDialogClose}
              color="error"
            >
              {t("close")}
            </Button> */}
            <Link
              to="/"
              className="btn btn-custom"
              style={{
                margin: 0,
                padding: 10,
                backgroundColor: "#d32f2f",
                border: "none",
              }}
            >
              <i className="mdi mdi-close ms-2"></i>
              {t("close")}
            </Link>
            <Button
              variant="contained"
              onClick={handleDownloadPDF}
              color="success"
              sx={{ mx: 2 }}
            >
              {t("download")}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={openSnackBar.open} autoHideDuration={3000}>
          <Alert
            // onClose={handleClose}
            color={openSnackBar.color}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {openSnackBar.message}
          </Alert>
        </Snackbar>
        {/* {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )} */}
      </Box>
    </Box>
  );
}
