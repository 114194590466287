import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import logo from "../assets/images/Logo/logo png white.png";
import { useTranslation } from "react-i18next";
import i18n from "../lang/i18n";

const Header = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [activeLink, setActiveLink] = useState<any>();
  const [isSticky, setIsSticky] = useState("");

  window.onscroll = function () {
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 50) {
      setIsSticky("nav-sticky");
    } else {
      setIsSticky("");
    }
  };

  useEffect(() => {
    const activation = (event: any) => {
      const target = event.target.getAttribute("href");
      const navitem = event.target.parentElement;
      const element = document.querySelector(target);

      if (element) {
        navitem.classList.add("active");
        setActiveLink(navitem);
        if (activeLink && activeLink !== navitem) {
          activeLink.classList.remove("active");
        }
      }
    };

    const defaultLink = document.querySelector(".navbar-nav li.active");
    if (defaultLink) {
      defaultLink?.classList.add("active");
      setActiveLink(defaultLink);
    }

    const links = document.querySelectorAll(".navbar-nav a");
    links.forEach((link) => {
      link.addEventListener("click", activation);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", activation);
      });
    };
  }, [activeLink]);

  const { t } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        className={`fixed-top navbar-custom sticky ${isSticky}`}
      >
        <Container>
          {smallScreen && (
            <Button
              // sx={{ mx: 2 }}
              variant="contained"
              onClick={() =>
                changeLanguage(i18n.language === "en" ? "ar" : "en")
              }
            >
              {i18n.language === "en" ? "Arabic" : "English"}
            </Button>
          )}
          <Navbar.Brand className="logo">
            {/* <i className="mdi mdi-chart-donut-variant"></i> أوبين */}
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{ width: "75px", height: "75px", objectFit: "cover" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav as="ul" className="ms-auto mb-2 mb-lg-0">
              <li className="nav-item active">
                <Nav.Link href="#home">{t("home")}</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#blog">{t("about_us")}</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#service">{t("services")}</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#portfolio">{t("deals")}</Nav.Link>
              </li>
              {/* <li className="nav-item">
                <Nav.Link href="#price">Pricing</Nav.Link>
              </li> */}
              <li className="nav-item">
                <Nav.Link href="#about">{t("our_doctors")}</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#contact">{t("contact_us")}</Nav.Link>
              </li>
              {!smallScreen && (
                <Button
                  // sx={{ mx: 2 }}
                  variant="contained"
                  onClick={() =>
                    changeLanguage(i18n.language === "en" ? "ar" : "en")
                  }
                >
                  {i18n.language === "en" ? "Arabic" : "English"}
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
