import React from "react";
import Routes from "./Routes/index";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  const direction = i18n.language === "ar" ? "rtl" : "ltr";

  document.documentElement.dir = direction;

  localStorage.setItem("AsnanyTenantId", "3");
  localStorage.setItem("ClinicTenantId", "13");

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
