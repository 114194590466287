import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { forwardRef } from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface SelectClinicTypeProps {
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: any;
  required?: boolean;
}

export const SelectClinicType = forwardRef<any, SelectClinicTypeProps>(
  (props, ref) => {
    const { t } = useTranslation();

    const GroupHeader = styled("div")(({ theme }) => ({
      position: "sticky",
      top: "-8px",
      padding: "4px 10px",
      borderBottom: "1px solid #e0e0e0",
      color: "white",
      backgroundColor: theme.palette.primary.main,
    }));
    const GroupItems = styled("ul")({
      padding: 0,
    });

    return (
      <Autocomplete
        ref={ref}
        options={[
          { name: t("dental"), id: 1 },
          { name: t("clinic"), id: 2 },
        ]}
        {...props}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            required={props.required}
            {...params}
            label={t("select_type")}
            size="small"
            fullWidth
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    );
  }
);
